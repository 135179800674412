import React, {useState} from 'react'
import ReactGA from 'react-ga'
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Grid, Button, Input, Link, Text } from '@chakra-ui/react'
import { RichText } from 'prismic-reactjs'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import Messages from '../locales/messages'
import BookLinks from './BookLinks';

ReactGA.initialize( process.env.GA_ID )
const windowGlobal = typeof window !== 'undefined' && window

function setEmail(email) {
  if(!!windowGlobal)
  {
    windowGlobal.sessionStorage.setItem('email', email);
    return true
  }
  
  return false;
}

function getEmail()
{
  return windowGlobal ? windowGlobal.sessionStorage.getItem('email') : null;
}

const EmailBookBox = ({ textIntro, bookIdentifier, bookPdfUrl, bookLinks  }) => {
  const intl = useIntl()
  const recordedEmail = getEmail()
  
  const
    [stickOnScroll, setStickOnScroll] = useState(false),
    [emailRecorded, setEmailRecorded] = useState(recordedEmail !== null);


  useScrollPosition(({ prevPos, currPos }) => {
    // console.log( 'currPos', currPos)
    const isShow = currPos.y < -450
    if (isShow !== stickOnScroll) setStickOnScroll(isShow)
  }, [stickOnScroll])

  const submitBookForm = (e) => {
    e.preventDefault()

    let email = document.getElementById('email').value

    addToMailchimp(email ,
      {
        BOOK: bookIdentifier,
        LANG: intl.locale
      }
    ).then(() => {
        ReactGA.event({
          category: 'Newsletter',
          action: 'Subscribe',
          label: 'Footer'
        });

        setEmailRecorded(true)
        setEmail(email);
      })
  }

  return (
    <Box
      maxW="1100px"
      mx="auto"
      position={{ base:stickOnScroll ? 'fixed' : 'initial' }}
      top="0"
      left="0"
      right="0"
      zIndex="tooltip"
      backgroundColor={ stickOnScroll ? 'brand.cream' : 'transparent' }
      p={{ base:stickOnScroll ? '1rem' : '0', lg:stickOnScroll ? '2rem' : '0' }}
      w="100%"
      display={{ lg:stickOnScroll ? 'block': 'block'}}
    >
      {textIntro ?
        <Text
          fontSize={{ base:"18px", lg:"16px"}}
          mb="1rem"
          display={ stickOnScroll ? "none" : "block"}
          w="100%"
        >
          {RichText.asText(textIntro)}
        </Text>
      :null}
      { !emailRecorded ?
      <Grid
        // columns={{ base:1, lg:2 }}
        templateColumns={{ base:'100%', lg: '45% 55%' }}
        mt={{ base: stickOnScroll ? 0 : 0, lg:'0'}}
        as="form"
        onSubmit={(e) => { submitBookForm(e) }}
        boxShadow='sm'
      >
        <Input
          placeholder={ Messages[intl.locale]['footer.your_email'].toUpperCase() }
          background={'transparent'}
          borderStyle='solid'
          borderWidth='2px'
          borderColor='brand.orange'
          borderRadius='2px 0 0 2px'
          fontFamily="Open sans"
          color="brand.orange"
          outline="none"
          mb={{ base:stickOnScroll ? '.5rem' : 0, lg:0 }}
          px={{ base: '1rem', lg:'1rem' }}
          h={{ base:'40px', lg:'60px'}}
          type="email"
          id="email"
          textAlign={{ base:'center', lg:'left'}}
          required
          _focus={{
            outline:'none'
          }}
        />

        <Button
          type="submit"
          backgroundColor={"brand.orange"}
          h={{ base:'40px', lg:'60px'}}
          border="solid 2px"
          borderRadius="1px"
          color={"white"}
          textTransform="uppercase"
          fontFamily="Open Sans"
          fontWeight="700"
          px='1rem'
          borderColor={ stickOnScroll ? 'brand.orange' : 'brand.orange' }
          _hover={{
            border: "solid 1px",
            borderColor: "brand.orange",
            color: "brand.orange",
            backgroundColor: "brand.cream"
          }}
        >
          <FormattedMessage id='book.get.for.free' />
        </Button>
      </Grid>
      :
        <>
          <Text mb={6}>
            <FormattedMessage id="book.newsletter.success.notice" />
          </Text>
          <BookLinks links={bookLinks} />
        </>
      }
      <Text
        color='gray.600'
        mt='1rem'
        textTransform="uppercase"
        fontSize='11px'
        letterSpacing='0.05rem'
        fontWeight='600'
        fontFamily='Open Sans'
        display={ stickOnScroll ? 'none' : 'block'}
      >
        <FormattedMessage id="book.newsletter.notice" />
      </Text>
      
      <Link
        display="none"
        target="_blank"
        rel="noopener"
        id='bookPdfLink'
        href={bookPdfUrl}
        download={'Download Book'}>lien</Link>
    </Box>
  )

}


export default EmailBookBox
